const _ = require('underscore');
const logging = require('logging');
const Backbone = require('Backbone');
const Marionette = require('Marionette');

const ImageHelpers = require('@common/libs/helpers/app/ImageHelpers');

require('jquery.velocity');
require('@common/components/mediaPreview/image/ImagePreview.less');

class ImagePreview extends Marionette.ItemView {

  className() {
    return 'image-preview fit-parent';
  }

  getTemplate() {
    return `\
      <div class="image-wrapper fit-parent">
        <img src="" class="hidden"/>
      </div>\
    `;
  }

  ui() {
    return {
      img: 'img'
    };
  }

  behaviors() {
    if (this.getOption('zoomable')) {
      return {
        ZoomOverlay: {}
      };
    }
    return {};
  }

  initialize(options = {}) {
    ({
      altTextObject: this.altTextObject,
      skipGlobalHandler: this.skipGlobalHandler = false
    } = options);

    this._maxDimensions = this._setupMaxDimensionState(this.getOption('maxDimensions'));
    return this._maxDimensions;
  }

  onRender() {
    this._render();
  }

  onError(event) {
    if (this.isDestroyed) {
      return;
    }

    const target = event && event.target;
    if (this.refetchOnError) {
      this.model.fetch({
        skipGlobalHandler: this.skipGlobalHandler,
        success: () => {
          this._render({ refetchOnError: false });
        }
      });
    } else if (target && target.error) {
      logging.error(event);
    }
  }

  onLoad(event, imageTag) {
    if (this.isDestroyed) {
      return;
    }

    this._appendImage(imageTag);
    this._fadeIn();
  }

  onMaxDimensionsChange() {
    if (!this.isRendered) {
      return;
    }

    this._updateImageMaxDimensions(this.ui.img);
  }

  onDestroy() {
    this.$el.stop(true);
    this.$el.velocity('stop');
  }

  updateFile(file) {
    this.model = file;

    ImageHelpers.loadFileImage(this.model).then((event, imageTag) => {
      if (this.isDestroyed || (file.id !== this.model.id)) {
        return;
      }
      this._appendImage(imageTag);
    });
  }

  _render(options = {}) {
    this._toggleLoadingClass(true);

    const toggleOffLoading = _.bind(this._toggleLoadingClass, this, false);
    const triggerLoad = _.bind(this.triggerMethod, this, 'load');
    const triggerError = _.bind(this.triggerMethod, this, 'error');

    _.defer(() => {
      ({ refetchOnError: this.refetchOnError = true } = options);
      ImageHelpers.loadFileImage(this.model)
        .always(toggleOffLoading)
        .then(triggerLoad, triggerError);
    });
  }

  _setupMaxDimensionState(maxDimensions = new Backbone.Model()) {
    this.listenTo(maxDimensions, 'change', this.onMaxDimensionsChange);
    return maxDimensions;
  }

  _updateImageMaxDimensions($img) {
    $img.css(this._maxDimensions.toJSON());
  }

  _toggleLoadingClass(show = true) {
    this.$el.toggleClass('loading', show);
  }

  _appendImage(imageTag) {
    const $image = $(imageTag);
    $image.attr('data-media-id', 'image');

    if (!_.isEmpty(this.altTextObject)) {
      $image.attr('alt', this.altTextObject.preferred || this.altTextObject.default);
    } else {
      $image.attr('alt', this.model.getName());
    }

    this._updateImageMaxDimensions($image);

    this.ui.img.replaceWith($image);

    this.ui.img = $image;

  }

  _fadeIn() {
    this.$el.velocity({
      opacity: [1, 0]
    }, {
      duration: 160
    });
  }
}

module.exports = ImagePreview;

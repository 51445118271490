// Module
var code = `<% if (!singularType) { %>
  <div class="community-type-header ax-grid">
    <div>
      <h3><%- t(\`communitiesManagement.communityType.\${folderType}\`) %></h3>
    </div>
    <div class="ax-grid__col--auto-size">
      <a class="qa-view-all-<%- folderType %>" href="#hub/search/type-<%- folderType %>/1"><%- t('general.viewAll') %></a>
    </div>
  </div>
<% } %>
<div class="js-communities-region"></div>
`;
// Exports
module.exports = code;
const $ = require('jquery');
const _ = require('underscore');
const Backbone = require('Backbone');
const UrlHelpers = require('@common/libs/helpers/app/UrlHelpers');

const I18n = require('@common/libs/I18n');
const logging = require('logging');
const Community = require('@common/data/models/Community');
const Page = require('@common/components/discover/models/Page');
const PageType = require('@common/components/discover/enums/PageType');
const PageFactory = require('@common/components/discover/factories/PageFactory');
const AxonifyExceptionFactory = require('AxonifyExceptionFactory');
const AxonifyExceptionCode = require('@common/services/error/AxonifyExceptionCode');
const SearchCategoryEnum = require('@training/apps/training/enums/SearchCategoryEnum');
const SearchSubCategoryEnum = require('@training/apps/training/enums/SearchSubCategoryEnum');
const SearchPageDefinition = require('@training/apps/search/SearchPageControllerDefinitionFactory');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');

const ArticleEditControllerFactory = require('@training/apps/articles/ArticleEditControllerFactory');
const PostDetailsPageControllerFactory = require('@training/apps/articles/PostDetailsPageControllerFactory');
const ArticleDetailsPageControllerFactory = require('@training/apps/articles/ArticleDetailsPageControllerFactory');
const PageHistoryControllerFactory = require('@training/apps/articles/PageHistoryControllerFactory');
const PageInsightsController = require('@training/apps/insights/PageInsightsController');
const MultilingualArticleBundledLanguageStatusModel = require('@training/apps/articles/multilingualArticleBundledLanguageChooser/MultilingualArticleBundledLanguageStatusModel');

const CommunityList = require('@common/data/collections/CommunityList');
const UserMetadata = require('@common/data/models/UserMetadata');

const { parseStringId } = require('@common/libs/helpers/app/UrlHelpers');
const ViewHelpers = require('@common/libs/helpers/app/ViewHelpers');
const FolderType = require('@training/apps/training/enums/FolderType');

const communityList = new CommunityList({});

const userMetadata = new UserMetadata();

/**
 Temporary routing for guest users until the proper flow controller work is completed
 */
class GuestRouter extends Backbone.Router {
  routes() {
    return {
      'hub/search/article/create/new-:articleType(/)(:languageCode)': 'articleCreateView',
      'hub/search/article/:articleId': 'articleDetailsView',
      'hub/search/article/:articleId/history': 'articleEditHistory',
      'hub/search/article/:articleId/edit': 'articleDetailsEditView',
      'hub/search/article/:pageId/insights(/)(team-:teamid)': 'pageInsights',
      'hub/search/article/:articleId/:attachmentId': 'articleAttachmentView',

      'hub/search/articles/reports': 'articleReports',
      'hub/search/articles/top_pages': 'reportsTopPages',
      'hub/search/articles/top_keywords_in_search': 'reportsTopKeywords',
      'hub/search/articles/top_keywords_in_search_empty': 'reportsTopKeywordsEmptySearch',

      'hub/search/articles/tags-:tagList/:pageNum(/)(:string)': 'taggedArticleSearch',
      'hub/search/community-:communityId/articles/tags-:tagList(/)(:pageNum)(/)(:string)': 'taggedCommunitySearch',
      'hub/search/community-:communityId(/)(:tabName)(/)(:pageNum)(/)(:string)': 'communitySearch',
      'hub/search/all/community-:communityId(/)': 'allCommunitySearch',
      'hub/search/articles/:pageNum(/)(:string)': 'articleSearch',
      'hub/search/type-:folderType/:pageNum(/)(:string)': 'folderTypeSearch',
      'hub/search/articles/needs-review(/)(:pageNum)(/)': 'needsReviewResults',

      '*action': 'default'
    };
  }

  default() {
    this.navigate('hub/search/type-all/1/', {
      trigger: true,
      replace: true
    });
  }

  languageIsSupported(languageCode) {
    const supportedLanguageCodes = TenantPropertyProvider.get().getProperty('languages');
    return _.contains(supportedLanguageCodes, languageCode);
  }

  articleCreateView(pageType, languageCode) {
    if (languageCode && !this.languageIsSupported(languageCode.toUpperCase())) {
      this._navigateBackToSearchAfterError();
      return;
    }

    const queryParams = UrlHelpers.getQueryParams(UrlHelpers.getQueryString(window.location.hash));
    const languageCodeUpper = languageCode ? languageCode.toUpperCase() : null;
    const page = new Page();
    page.set('type', pageType);

    if (queryParams.communityId) {
      page.set('community', new Community({id: queryParams.communityId}));
    }

    if (languageCodeUpper) {
      page.set('language', languageCodeUpper);
    } else {
      const userLanguage = window.apps.auth.session.user.get('language');
      page.set('language', userLanguage);
    }

    const typedPage = this._getTypedPage(page);

    typedPage.set('currentVersion', {
      richContent: {
        media: []
      }
    });

    if (queryParams.fromArticle) {
      this.fetchPrefilledModelBeforeSettingCreateView(typedPage, new Page({id: queryParams.fromArticle}));
    } else {
      this.fetchLanguagesBeforeSettingCreateView(typedPage);
    }
  }

  fetchPrefilledModelBeforeSettingCreateView(typedPage, existingPage) {
    existingPage.fetch({
      success: () => {
        // there are only certain fields that we will allow to be prefilled
        // NOTE: copying permittedPageActions assumes that these permissions are the same accross all language-variants of an article (this may change)
        _.each(['communities', 'bundleId', 'recommendable', 'community', 'permittedPageActions'], (field) => {
          typedPage.set(field, existingPage.get(field));
        });
        this.fetchLanguagesBeforeSettingCreateView(typedPage);
      },
      error: (model, xhr) => {
        this._onLanguageDataFetchError(xhr);
      }
    });
  }

  fetchLanguagesBeforeSettingCreateView(typedPage) {
    const bundleId = typedPage.get('bundleId');
    if (!bundleId) {
      // this new article is not part of a bundle, so we can set the language data to be a static (sort of)
      // list of supported languages
      const languageData = this._getEmptyLanguageCollection();
      this.fetchCommunityBeforeSettingCreateView(typedPage, languageData);
    } else {
      // this new article is part of a bundle, so we need to fetch the language data for that bundle
      const languageData = new MultilingualArticleBundledLanguageStatusModel({
        bundleId: bundleId
      });
      languageData.fetch({
        success: () => {
          this.fetchCommunityBeforeSettingCreateView(typedPage, languageData);
        },
        error: (model, xhr) => {
          this._onLanguageDataFetchError(xhr);
        }
      });
    }
  }

  fetchCommunityBeforeSettingCreateView(typedPage, languageData) {
    const community = typedPage.get('community');
    if (community != null && community.get('id') != null && community.get('translations') == null) {
      community.fetch({
        success: () => {
          window.app.layout.setView(ArticleEditControllerFactory(typedPage, window.apps.auth.session.user, languageData));
        },
        error: (model, xhr) => {
          this._onCommunityFetchError(xhr);
        }
      });
    } else {
      // when there is no community ID, it is safe to render this view without fetching the community data. The view assumes
      // that the presence of a community ID means the data for it has been fetched
      window.app.layout.setView(ArticleEditControllerFactory(typedPage, window.apps.auth.session.user, languageData));
    }
  }

  _getEmptyLanguageCollection() {
    const supportedLanguageCodes = TenantPropertyProvider.get().getProperty('languages');
    const languageData = _.mapObject(_.invert(supportedLanguageCodes), () => {
      return null;
    });
    return new MultilingualArticleBundledLanguageStatusModel(languageData);
  }

  /**
   * Admin users do not get magic redirection to another language, everyone else does.
   * @returns bool
   */
  isAllowedToUseMultingualRedirectionMagic() {
    return !window.apps.auth.session.user.isAdminUser();
  }


  articleDetailsView(articleIdString) {
    const articleId = parseStringId(articleIdString);

    if (articleId == null) {
      window.app.layout.flash.error(I18n.t('selfDirected.articleDetails.errors.noId'));
      this.navigate('hub/search/articles/1/', {
        trigger: true,
        replace: true
      });
      return;
    }

    const page = new Page({
      id: articleId
    });

    const fetchOptions = {};

    if (this.isAllowedToUseMultingualRedirectionMagic()) {
      fetchOptions.data = {
        usePreferredLanguagesFallback: true
      };
    }

    fetchOptions.success = (contextArg, modelArg) => {
      if (articleId !== modelArg.id) {
        Backbone.history.navigate(`#hub/search/article/${ modelArg.id }`, {
          trigger: false,
          replace: true
        });
      }

      const typedPage = this._getTypedPage(page);
      typedPage.view();

      if (typedPage.get('type') === PageType.POST) {
        window.app.layout.setView(PostDetailsPageControllerFactory(typedPage, window.apps.auth.session.user));
      } else {

        const bundleId = typedPage.get('bundleId');

        if (!bundleId) {
          // this is an article creation, where all the language data should be null. We do not request from the API for that.
          const languageData = this._getEmptyLanguageCollection();
          window.app.layout.setView(ArticleDetailsPageControllerFactory(typedPage, window.apps.auth.session.user, languageData));

        } else {
          const languageData = new MultilingualArticleBundledLanguageStatusModel({
            bundleId: bundleId
          });
          languageData.fetch({
            success: () => {
              window.app.layout.setView(ArticleDetailsPageControllerFactory(typedPage, window.apps.auth.session.user, languageData));
            },
            error: (model, xhr) => {
              this._onLanguageDataFetchError(xhr);
            }
          });
        }
      }
    };

    fetchOptions.error = (model, xhr) => {
      // This is the fail condition - display an error message
      this._logError(xhr);

      window.app.layout.flash.error(I18n.t('selfDirected.articleDetails.errors.notAvailable', // For now I'm going with a generic error
        {articleId}));

      this._navigateBackToSearchAfterError();
    };

    page.fetch(fetchOptions);
  }

  articleEditHistory(articleIdString) {
    const articleId = this._isArticleIdNull(articleIdString);

    if (!articleId) {
      return;
    }

    const page = new Page({
      id: articleId
    });

    page.fetch({
      success: () => {
        const typedPage = this._getTypedPage(page);

        typedPage.view();

        window.app.layout.setView(PageHistoryControllerFactory(typedPage));
      },
      error: (model, xhr) => {
        this._logError(xhr);

        window.app.layout.flash.error(I18n.t('selfDirected.articleDetails.errors.notAvailable', {articleId}));

        this._navigateBackToSearchAfterError();
      }
    });
  }

  articleDetailsEditView(articleIdString) {
    const articleId = this._isArticleIdNull(articleIdString);

    if (!articleId) {
      return;
    }

    const page = new Page({
      id: articleId
    });

    page.fetch({
      success: () => {
        const typedPage = this._getTypedPage(page);
        typedPage.view();
        this.fetchLanguageDataBeforeEditView(typedPage);
      },
      error: (model, xhr) => {
        this._logError(xhr);

        window.app.layout.flash.error(I18n.t('selfDirected.articleDetails.errors.notAvailable', {articleId}));

        this._navigateBackToSearchAfterError();
      }
    });
  }

  fetchLanguageDataBeforeEditView(typedPage) {
    const bundleId = typedPage.get('bundleId');

    if (!bundleId) {
      // this is an article creation, where all the language data should be null. We do not request from the API for that.
      const languageData = this._getEmptyLanguageCollection();
      this.fetchCommunityDataBeforeEditView(typedPage, languageData);

    } else {
      const languageData = new MultilingualArticleBundledLanguageStatusModel({
        bundleId: bundleId
      });
      languageData.fetch({
        success: () => {
          this.fetchCommunityDataBeforeEditView(typedPage, languageData);
        },
        error: (model, xhr) => {
          this._onLanguageDataFetchError(xhr);
        }
      });
    }
  }

  fetchCommunityDataBeforeEditView(typedPage, languageData) {
    const community = typedPage.get('community');
    community.fetch({
      success: () => {
        window.app.layout.setView(ArticleEditControllerFactory(typedPage, window.apps.auth.session.user, languageData));
      },
      error: (model, xhr) => {
        this._onCommunityFetchError(xhr);
      }
    });
  }


  pageInsights(pageId, teamId) {
    window.app.layout.setView({
      ViewControllerClass: PageInsightsController,
      pageId,
      teamId
    }, 'fade');
  }

  articleSearch(pageNumString, searchString) {
    const pageNum = parseStringId(pageNumString);
    const queryString = searchString || '';

    this._fetchCommunityListAndMetadata().then(() => {
      window.app.layout.setView(SearchPageDefinition({
        searchCategory: SearchCategoryEnum.ARTICLES,
        startTrainingFn: $.noop,
        searchString: queryString,
        pageNum,
        communityList,
        userMetadata
      }));
    });
  }

  folderTypeSearch(folderType, pageNumString, searchString) {
    const pageNum = parseStringId(pageNumString);
    const queryString = searchString || '';

    this._fetchCommunityListAndMetadata(folderType).then(() => {
      window.app.layout.setView(SearchPageDefinition({
        searchCategory: SearchCategoryEnum.ARTICLES,
        startTrainingFn: $.noop,
        searchString: queryString,
        pageNum,
        communityList,
        userMetadata,
        folderType
      }));
    });
  }

  needsReviewResults(pageNumString) {
    const pageNum = parseStringId(pageNumString);

    this._fetchCommunityListAndMetadata().then(() => {
      window.app.layout.setView(SearchPageDefinition({
        searchCategory: SearchCategoryEnum.ARTICLES,
        subCategory: SearchSubCategoryEnum.PENDING,
        pageNum,
        communityList,
        userMetadata
      }));
    });
  }

  articleReports() {
    this._fetchCommunityListAndMetadata().then(() => {
      window.app.layout.setView(SearchPageDefinition({
        searchCategory: SearchCategoryEnum.ARTICLES,
        subCategory: SearchSubCategoryEnum.ARTICLE_REPORTS,
        communityList,
        userMetadata
      }));
    });
  }

  reportsTopPages() {
    this._fetchCommunityListAndMetadata().then(() => {
      window.app.layout.setView(SearchPageDefinition({
        searchCategory: SearchCategoryEnum.ARTICLES,
        subCategory: SearchSubCategoryEnum.TOP_PAGES,
        communityList,
        userMetadata
      }));
    });
  }

  reportsTopKeywords() {
    this._fetchCommunityListAndMetadata().then(() => {
      window.app.layout.setView(SearchPageDefinition({
        searchCategory: SearchCategoryEnum.ARTICLES,
        subCategory: SearchSubCategoryEnum.TOP_KEYWORDS_IN_SEARCH,
        communityList,
        userMetadata
      }));
    });
  }

  reportsTopKeywordsEmptySearch() {
    this._fetchCommunityListAndMetadata().then(() => {
      window.app.layout.setView(SearchPageDefinition({
        searchCategory: SearchCategoryEnum.ARTICLES,
        subCategory: SearchSubCategoryEnum.TOP_KEYWORDS_EMPTY_SEARCH,
        communityList,
        userMetadata
      }));
    });
  }

  articleAttachmentView(articleIdString, attachmentIdString) {
    const articleId = this._isArticleIdNull(articleIdString);

    if (!articleId) {
      return;
    }

    if (articleId == null) {
      window.app.layout.flash.error(I18n.t('selfDirected.articleDetails.errors.noId'));
      this.navigate(`hub/search/${ SearchCategoryEnum.ARTICLES }/1`, {
        trigger: true,
        replace: true
      });
      return;
    }

    const attachmentId = parseStringId(attachmentIdString);
    if (attachmentId == null) {
      window.app.layout.flash.error(I18n.t('discover.pageTypes.fetch.error'));
      this.navigate(`hub/search/article/${ articleId }`, {
        trigger: true,
        replace: true
      });
      return;
    }

    const page = new Page({
      id: articleId
    });

    page.fetch().done( () => {
      const documentJson = _.find( ((page.get('currentVersion') || {}).richContent || {}).media || [], (media) => {
        return media.id === attachmentId;
      });
      if (!documentJson) { //media is invalid.  Possibly deleted
        window.app.layout.flash.error(I18n.t('discover.pageTypes.fetch.error'));
        return;
      }

      const PdfView = require('@common/components/mediaFilePreview/views/PdfContentShowView');
      const FileFactory = require('@common/data/models/media/FileFactory');
      const fileFactory = new FileFactory();
      const documentMedia = fileFactory.createMediaFromJSON(documentJson);

      const view = {
        viewDefinition: {
          ViewClass: PdfView,
          model: documentMedia
        },
        delegateEvents: {
          'view:inflate': () => {
            window.app.layout.toggleFooter(false);
            window.app.layout.toggleFullPage(true);
          },
          'view:before:destroy': () => {
            window.app.layout.toggleFooter(true);
            window.app.layout.toggleFullPage(false);
          },
          'view:show': function (controller, pdfView) {
            ViewHelpers.showBackButtonWithReset({view: pdfView});
          }
        }
      };

      window.app.layout.setView(view);
    })
      .fail( (xhr) => {
      // This is the fail condition - display an error message
        this._logError(xhr);

        window.app.layout.flash.error(I18n.t('selfDirected.search.notAvailable.primaryMessage', { articleId }));

        this.navigate(`hub/search/article/${ articleId }`, {
          trigger: true,
          replace: true
        });
      });
  }

  taggedArticleSearch(tagListString, pageNumString, searchString) {
    const queryString = searchString || '';
    const tagList = tagListString || '';
    const pageNum = parseStringId(pageNumString);

    this._fetchCommunityListAndMetadata().then(() => {
      window.app.layout.setView(SearchPageDefinition({
        searchString: queryString,
        searchCategory: SearchCategoryEnum.ARTICLES,
        startTrainingFn: $.noop,
        pageNum,
        tagList,
        communityList,
        userMetadata
      }));
    });
  }

  taggedCommunitySearch(communityIdString, tagListString, pageNumString, searchString) {
    const queryString = searchString || '';
    const communityId = parseStringId(communityIdString);
    const tagList = tagListString || '';
    const pageNum = parseStringId(pageNumString);

    // use the community provided/parsed as a filter for the selected search
    const community = new Community({ id: communityIdString });
    community.fetch({error: (model, xhr) => {
      this._onCommunityFetchError(xhr);
    }});

    this._fetchCommunityListAndMetadata().then(() => {
      window.app.layout.setView(SearchPageDefinition({
        searchString: queryString,
        searchCategory: SearchCategoryEnum.ARTICLES,
        subCategory: SearchSubCategoryEnum.COMMUNITY,
        startTrainingFn: $.noop,
        communityId,
        tagList,
        pageNum,
        communityList,
        userMetadata,
        community
      }));
    });
  }

  communitySearch(communityIdString, tabName, pageNumString, searchString) {
    const queryString = searchString || '';
    const communityId = parseStringId(communityIdString);
    const pageNum = parseStringId(pageNumString);

    // use the community provided/parsed as a filter for the selected search
    const community = new Community({ id: communityIdString });
    community.fetch({error: (model, xhr) => {
      this._onCommunityFetchError(xhr);
    }});

    this._fetchCommunityListAndMetadata().then(() => {
      window.app.layout.setView(SearchPageDefinition({
        searchString: queryString,
        searchCategory: SearchCategoryEnum.ARTICLES,
        subCategory: SearchSubCategoryEnum.COMMUNITY,
        startTrainingFn: $.noop,
        communityId,
        pageNum,
        communityList,
        userMetadata,
        tabName,
        community
      }));
    });
  }

  allCommunitySearch(communityIdString, pageNumString, queryString) {
    const searchString = queryString || '';
    const communityId = parseStringId(communityIdString);
    const pageNum = parseStringId(pageNumString);

    // use the community provided/parsed as a filter for the selected search
    this._fetchCommunitiesMetaData().then((data) => {
      window.app.layout.setView(SearchPageDefinition({
        searchString,
        searchCategory: SearchCategoryEnum.ALL,
        subCategory: SearchSubCategoryEnum.COMMUNITY,
        startTrainingFn: $.noop,
        communityId,
        pageNum,
        communitiesData: data.result
      }));
    });
  }

  //Reused private functions
  _fetchCommunityListAndMetadata(folderType = null) {
    const options = {};
    if (folderType && folderType !== FolderType.ALL) {
      options.data = { folderType };
    }

    const promise = Promise.all([communityList.fetch(), userMetadata.fetch()]);
    return promise;
  }

  _isArticleIdNull(articleIdString) {
    const articleId = parseStringId(articleIdString);

    if (!articleId) {
      window.app.layout.flash.error(I18n.t('selfDirected.articleDetails.errors.noId'));
      this.navigate(`hub/search/articles/1/`, {
        trigger: true,
        replace: true
      });

      return false;
    }

    return articleId;
  }

  _getTypedPage(page) {
    const pageFactory = new PageFactory();
    return pageFactory.getTypedPage(page);
  }

  _logError(xhr) {
    const exception = AxonifyExceptionFactory.fromResponse(xhr);
    logging.error(exception.getErrorMessage());
    xhr.skipGlobalHandler = true;
  }

  _onCommunityFetchError(xhr) {
    xhr.skipGlobalHandler = true;

    const exception = AxonifyExceptionFactory.fromResponse(xhr);
    logging.error(exception.getErrorMessage());

    let error = I18n.t('errors.genericError');
    if (exception.getErrorCode() === AxonifyExceptionCode.CLIENT_ERROR_NOT_AUTHORIZED) {
      error = I18n.t('discover.pageAccess.community.error.3017');
    } else if (exception.getErrorCode() === AxonifyExceptionCode.CLIENT_ERROR_NO_SUCH_ENTITY) {
      error = I18n.t('discover.pageAccess.community.error.3001');
    }

    window.app.layout.flash.error(error);
    Backbone.history.navigate('#hub/search/type-all/1/', true);
  }

  _onLanguageDataFetchError(xhr) {
    xhr.skipGlobalHandler = true;

    const exception = AxonifyExceptionFactory.fromResponse(xhr);
    logging.error(exception.getErrorMessage());

    // TODO: figure out the errors that could be returned from this and whether we want to handle them individually
    const error = I18n.t('errors.genericError');

    window.app.layout.flash.error(error);
    Backbone.history.navigate('#hub/search/type-all/1/', true);
  }


  _navigateBackToSearchAfterError() {
    this._fetchCommunityListAndMetadata().then(() => {
      window.app.layout.setView(SearchPageDefinition({
        searchCategory: SearchCategoryEnum.ARTICLES,
        startTrainingFn: $.noop,
        showNotAvailable: true,
        communityList,
        userMetadata
      }));
    });
  }
}

module.exports = GuestRouter;

import I18n from '@common/libs/I18n';
import {
  AxIcon,
  AxTypography
} from '@common/modules/react/themes/components/data-display';
import { useIsMobile } from '@common/util/useScreenSize';
import {
  Pagination as MuiPagination,
  PaginationItem,
  styled,
  type PaginationProps as MuiPaginationProps
} from '@mui/material';
import {
  forwardRef,
  type Ref
} from 'react';

type StyledPaginationProps = MuiPaginationProps;
type PropsToOmit = 'color' | 'shape' | 'size' | 'variant';
export type AxPaginationProps = Omit<MuiPaginationProps, PropsToOmit>;

const styledOptions = {
  name: 'AxPagination'
};

const StyledAxPagination = styled(MuiPagination, styledOptions)<StyledPaginationProps>(({theme}) => {
  return {
    '& .MuiPaginationItem-root': {
      '&.Mui-selected': {
        background: theme.uiKit.pagination.pageItem.selected.background,
        border: theme.uiKit.pagination.pageItem.selected.border
      }
    },
    '& .MuiPaginationItem-page': {
      padding: theme.uiKit.pagination.pageItem.padding,
      fontWeight: theme.uiKit.pagination.pageItem.fontWeight,
      color: theme.uiKit.pagination.pageItem.color
    }
  };
});

/**
 *
 * A wrapper around the MUI Pagination component.
 *
 * The component will automatically adjust its appearance based on the screen size.
 *
 * > ℹ️ NOTE <br />
 * > Backbokne legacy component has slightly different behavior.
 *
 * ### Links
 * - • [MUI | Pagination Demo](https://mui.com/components/pagination/)
 * - • [MUI | Pagination API](https://mui.com/api/pagination/)
 * - • [Figma](https://www.figma.com/design/mAGiAo8UwCUfdpW9SNdswW/%F0%9F%A7%B0-UIKit?node-id=7246-4620&t=BPPgE7IOGBGfoTJy-0)
*/
export const AxPagination = forwardRef(({
  ...otherProps
}: AxPaginationProps, ref: Ref<HTMLDivElement>) => {
  const isMobile = useIsMobile();
  const isRtl = I18n.isCurrentLanguageRtl();

  const paginationProps: StyledPaginationProps = {
    size: isMobile ? 'small' : 'medium',
    siblingCount: isMobile ? 0 : 1,
    showFirstButton: !isMobile,
    showLastButton: !isMobile,
    ...otherProps
  };

  return (
    <StyledAxPagination
      { ...paginationProps }
      ref={ ref }
      shape='rounded'
      renderItem={ (item) => {
        return (
          <PaginationItem
            slots={{
              first: () => {
                return (
                  <>
                    <AxIcon className={ `icon-double_angle_left ${ isRtl ? 'qa-last' : 'qa-first' }` } sx={{ mr: 1 }} />
                    <AxTypography component='span' fontWeight='bold'>{ isRtl ? I18n.t('general.last') : I18n.t('general.first') }</AxTypography>
                  </>
                );
              },
              last: () => {
                return (
                  <>
                    <AxTypography
                      component='span'
                      fontWeight='bold'
                      sx={{ mr: 1 }}>{ isRtl ? I18n.t('general.first') : I18n.t('general.last') }</AxTypography>
                    <AxIcon className={ `icon-double_angle_right qa-last ${ isRtl ? 'qa-first' : 'qa-last' }` } />
                  </>
                );

              },
              next: () => {
                return isRtl ? <AxIcon className='icon-angle_left qa-next' /> : <AxIcon className='icon-angle_right qa-next' />;
              },
              previous: () => {
                return isRtl ? <AxIcon className='icon-angle_right qa-previous' /> : <AxIcon className='icon-angle_left qa-previous' />;
              }
            }}
            { ...item }
          />
        );
      } }
    />
  );
});

export default AxPagination;

import UiKit from '@common/modules/react/themes/UiKit';
import {
  AxIcon,
  AxIconButton,
  AxInputAdornment,
  AxTextField
} from '@common/modules/react/themes/components';
import { styled } from '@mui/material';
import {
  LocalizationProvider,
  DatePicker as MuiDatePicker,
  type PickerValidDate as MuiPickerValidDate
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {
  forwardRef,
  type Ref
} from 'react';

import I18n from '@common/libs/I18n';
import { type DatePickerProps as MuiDatePickerProps } from '@mui/x-date-pickers';

export interface AxDatePickerProps<
  TDate extends MuiPickerValidDate,
  TEnableAccessibleFieldDOMStructure extends boolean = false
> extends MuiDatePickerProps<TDate, TEnableAccessibleFieldDOMStructure> {
  required?: boolean;
}

const styledOptions = {
  name: 'AxDatePicker'
};

const StyledAxDatePicker = styled(MuiDatePicker, styledOptions)(() => {
  // AxDatePicker uses different components (slots) for the text field and input adornment.
  // The styles are defined within the components.
  return {};
});

/**
 * A date picker component that uses the MUI X date picker.
 *
 * #### Links
 * - • [MUI Demo](https://mui.com/x/react-date-pickers/date-picker/)
 * - • [MUI API](https://mui.com/x/api/date-pickers/date-picker/)
 */
export const AxDatePicker = forwardRef(<
  TDate extends MuiPickerValidDate,
  TEnableAccessibleFieldDOMStructure extends boolean = false
> (
    {
      slots = {},
      slotProps = {},
      format = 'LL',
      required = false,
      ...DatePickerProps
    }: AxDatePickerProps<TDate, TEnableAccessibleFieldDOMStructure>,
    ref: Ref<HTMLDivElement>
  ) => {

  return (
    <LocalizationProvider dateAdapter={ AdapterMoment } adapterLocale='userLocale'>
      <StyledAxDatePicker
        format={ format }
        { ...DatePickerProps }
        slots={{
          ...slots,
          textField: AxTextField,
          inputAdornment: AxInputAdornment,
          openPickerButton: AxIconButton,
          openPickerIcon: AxIcon
        }}
        slotProps={{
          ...slotProps,
          textField: {
            required: required,
            placeholder: I18n.t('mui.datepicker.selectDate'),
            ...(slotProps?.textField ?? {})
          },
          openPickerButton: {
            size: 'medium',
            ...(slotProps?.openPickerButton ?? {})
          },
          openPickerIcon: {
            className: 'icon-frequenttraining',
            ...(slotProps?.openPickerIcon ?? {})
          },
          layout: {
            sx: {
              ...defaultSlotStyles,
              ...slotProps?.layout?.sx
            }
          }
        }}
        ref={ ref }
      />
    </LocalizationProvider>
  );
});

export default AxDatePicker;

const defaultSlotStyles = {
  '.MuiPickersDay-root': {
    '&.Mui-selected': {
      backgroundColor: UiKit.datePicker.selectedDay.backgroundColor,
      color: UiKit.datePicker.selectedDay.color,
      '&:focus': {
        backgroundColor: UiKit.datePicker.selectedDay.backgroundColor,
        color: UiKit.datePicker.selectedDay.color
      },
      '&:hover': {
        backgroundColor: UiKit.datePicker.selectedDay.backgroundColor,
        color: UiKit.datePicker.selectedDay.color
      }
    }
  },
  '.MuiPickersDay-today': {
    borderColor: UiKit.datePicker.today.borderColor,
    backgroundColor: UiKit.datePicker.today.backgroundColor,
    '&:focus': {
      borderColor: UiKit.datePicker.today.borderColor,
      backgroundColor: UiKit.datePicker.today.backgroundColorHover
    },
    '&:hover': {
      borderColor: UiKit.datePicker.today.borderColor,
      backgroundColor: UiKit.datePicker.today.backgroundColorHover
    }
  }
};

import {
  TableCell as MuiTableCell,
  styled,
  type TableCellProps as MuiTableCellProps
} from '@mui/material';
import {
  forwardRef,
  type Ref
} from 'react';

type AxTableCellProps = MuiTableCellProps;

const styledOptions = {
  name: 'AxTableCell'
};

const StyledAxTableCell = styled(MuiTableCell, styledOptions)<AxTableCellProps>(({theme}) => {
  return {
    // Default padding for all cells
    padding: theme.uiKit.spacingS,
    '&.MuiTableCell-body': {
      ...theme.uiKit.table.cell
    },
    '&.MuiTableCell-head': {
      ...theme.uiKit.table.header
    },
    '&.MuiTableCell-paddingNone': {
      padding: 0
    },
    '&.MuiTableCell-sizeSmall': {
      padding: theme.uiKit.spacingXS
    }
  };
});

/**
 * A cell in a table. Can be used in `<AxTableRow />`. Renders as a `<th>` in `<AxTableHead />` and `<td>` in `<AxTableBody />` by default.
 *
 * ## Links
 * - • [MUI | Table API](https://mui.com/material-ui/api/table-cell/)
 */
export const AxTableCell = forwardRef(({
  children,
  ...otherProps
}: AxTableCellProps, ref: Ref<HTMLTableCellElement>) => {
  const tableCellProps: AxTableCellProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxTableCell { ...tableCellProps } ref={ ref }>
      { children }
    </StyledAxTableCell>
  );
});

export default AxTableCell;


export default function uiKitTable() {
  return {
    table: {
      header: {
        backgroundColor: 'var(--ax-table-header-background-color)',
        padding: 'var(--ax-table-header-padding)',
        color: 'var(--ax-table-header-color)',
        fontSize: 'var(--ax-table-header-font-size)',
        fontWeight: 'var(--ax-table-header-font-weight)',
        lineHeight: 'var(--ax-table-header-line-height)'
      },
      cell: {
        fontSize: 'var(--ax-table-cell-font-size)',
        lineHeight: 'var(--ax-table-cell-line-height)',
        fontWeight: 'var(--ax-table-cell-font-weight)',
        color: 'var(--ax-table-cell-color)',
        padding: 'var(--ax-table-cell-padding)'
      }
    }
  };
}

import {
  Children,
  Fragment,
  isValidElement,
  type ReactElement,
  type ReactFragment,
  type ReactNode
} from 'react';

/**
 * Checks if a React element is a Fragment
 */
export const isFragment = (child: ReactNode): child is ReactFragment => {
  return (
    isValidElement(child) && (child.type === Fragment)
  );
};

/**
* Extracts children from a Fragment or returns the original elements if not a Fragment
*
* @example
* <>
*   <AxButton>One</AxButton>
*   <AxButton>Two</AxButton>
*   <AxButton>Three</AxButton>
* </>
*
* extractFragmentChildren(children)
* // returns [ <AxButton>One</AxButton>, <AxButton>Two</AxButton>, <AxButton>Three</AxButton> ]
*
*/
export const extractFragmentChildren = (children: ReactNode): ReactNode[] => {

  if (Array.isArray(children)) {
    return children;
  }

  // If it's a single Fragment, return its children as an array
  if (isFragment(children) && isValidElement(children)) {
    return Children.toArray((children as ReactElement).props.children);
  }

  return [children];
};

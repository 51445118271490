import {
  Radio as MuiRadio,
  styled,
  type RadioProps as MuiRadioProps
} from '@mui/material';
import {
  forwardRef,
  type Ref
} from 'react';

type PropsToOmit = 'color'
  | 'centerRipple'
  | 'disableRipple'
  | 'disableTouchRipple'
  | 'focusRipple'
  | 'TouchRippleProps'
  | 'touchRippleRef'
  | 'disableFocusRipple'
  | 'size';

export type AxRadioProps = Omit<MuiRadioProps, PropsToOmit> & {
    /**
     * If `true`, the radio won't use the branded color.
     * @default false
     * @optional
     */
    disabledBranding?: boolean
  };

const styledOptions = {
  name: 'AxRadio',
  shouldForwardProp: (prop: string) => {
    return prop !== 'disabledBranding';
  }
};

const StyledAxRadio = styled(MuiRadio, styledOptions)<AxRadioProps>(({ theme }) => {
  return {
    padding: 0,
    '&.Mui-focusVisible': theme.mixins.componentKeyboardFocusStyles(),
    '&.Mui-checked .MuiSvgIcon-root': {
      color: theme.uiKit.linkColor
    },
    '& .MuiSvgIcon-root': {
      color: theme.uiKit.colorGrey50,
      height: 20,
      width: 20
    }
  };
});

interface RadiodIconProps {
  disabled: boolean;
  disabledBranding?: boolean;
}

const CheckedIcon = ({
  disabled,
  disabledBranding
} :RadiodIconProps) => {

  let className = 'ax-radio_icon--checked-branded';

  if (disabled) {
    className = 'ax-radio_icon--checked-disabled';
  } else if (disabledBranding) {
    className = 'ax-radio_icon--checked';
  }

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      className={ className }>
      <circle
        className='outer-ring'
        cx='12'
        cy='12'
        r='9.5'
        fill='white'/>
      <circle
        className='fill'
        cx='12'
        cy='12'
        r='5' />
    </svg>
  );
};

const UncheckedIcon = ({
  disabled,
  disabledBranding
}: RadiodIconProps) => {
  let className = 'ax-radio_icon--unchecked-branded';

  if (disabled) {
    className = 'ax-radio_icon--unchecked-disabled';
  } else if (disabledBranding) {
    className = 'ax-radio_icon--unchecked';
  }

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'>
      <circle
        cx='12'
        cy='12'
        r='9.5'
        className={ className }/>
    </svg>
  );
};

/**
 * AxRadio is a styled MUI Radio component.
 *
 * **💡 Note**: This is a brandable component 💄
 *
 * **💡 Note 2**: Use [AxFormControlLabel](https://storybook.axonify.com/qa13/?path=/docs/mui-components-inputs-axform-axformcontrollabel--docs) for label placement
 *
  * ### Links
 * - [Figma](https://www.figma.com/design/mAGiAo8UwCUfdpW9SNdswW/%F0%9F%A7%B0-UIKit?node-id=13328-1047&m=dev)
 * - [MUI Radio API](https://mui.com/material-ui/api/radio/)
 */
export const AxRadio = forwardRef(({
  disabled = false,
  disabledBranding = false,
  ...otherProps
}: AxRadioProps, ref: Ref<HTMLButtonElement>) => {
  const RadioProps: AxRadioProps = {
    checkedIcon: <CheckedIcon disabled={ disabled } disabledBranding = { disabledBranding }/>,
    icon: <UncheckedIcon disabled={ disabled } disabledBranding = { disabledBranding }/>,
    disabled,
    ...otherProps
  };

  return (
    <StyledAxRadio
      { ...RadioProps }
      ref={ ref }
      disableFocusRipple
      disableRipple
      disableTouchRipple
    />
  );
});

export default AxRadio;
